import { ref, reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import ProfileAddRules from "@/rules/ProfileAddRules.js";
import { getCurrentUser, editCurrentUser } from "@/services/auth/authServices";
import store from "../../store";
export default function useChallengesForm() {
    const title_modal = ref("");
    const method_post = ref(true);
    const loading = ref(false);

    const form = reactive({
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        client: {
            facebook: "",
            phone_number: "",
            cell_phone_number: "",
            calle: "",
            numero: "",
            colonia: "",
            ciudad: "",
            pais: "",
            codigo_postal: "",
            instagram: "",
            birthdate: "",
            birthplace: "",
            civil_status: "",
            occupation: "",
            height: "",
            body_weight: "",
            blood_type: "",
            start_date: "",
            activation_type: "",
            find_out_gym: "",
            communicate_with: "",
            extra_phone_number: "",
            client_type_id: "",
            invoice: 0,
            padecimientos: [
                {
                    name: "",
                },
            ],
        },
    });

    const edit = async () => {
        NProgress.start();
        cleanForm();
        title_modal.value = "Editar perfil";
        method_post.value = false;

        const response = await getCurrentUser();

        if (response.status === 200) {
            Object.assign(form, response.data);
        } else {
            Swal.fire(
                "Error!",
                "No encontramos lo que buscaba!.",
                "error"
            ).then(() => {
                $("#modalAdd").modal("toggle");
            });
        }
        NProgress.done();
    };

    const cleanForm = () => {
        Object.assign(form, {
            name: "",
            email: "",
            password: "",
            confirm_password: "",
            client: {
                facebook: "",
                phone_number: "",
                cell_phone_number: "",
                calle: "",
                ciudad: "",
                pais: "",
                codigo_postal: "",
                instagram: "",
                numero: "",
                colonia: "",
                birthdate: "",
                birthplace: "",
                civil_status: "",
                occupation: "",
                height: "",
                body_weight: "",
                blood_type: "",
                start_date: "",
                activation_type: "",
                find_out_gym: "",
                communicate_with: "",
                extra_phone_number: "",
                client_type_id: "",
                invoice: 0,
                padecimientos: [
                    {
                        name: "",
                    },
                ],
            },
        });
        /**
         * Resetea el formulario
         */
        v$.value.$reset();
    };

    const { rules } = ProfileAddRules(method_post, form);

    const v$ = useVuelidate(rules, form, { $autoDirty: true });

    const submitForm = async (e) => {
        const isFormCorrect = await v$.value.$validate();
        if (!isFormCorrect) return;

        // const formData = new FormData()
        const formData = new FormData(e.target);

        loading.value = true;
        const response = await editCurrentUser(formData);
        if (response.status === 200) {
            store.dispatch("User/setUser", response.data);
            Swal.fire(
                "Hecho",
                "Proceso completado correctamente!",
                "success"
            ).then(() => {
                $("#modalAdd").modal("toggle");
            });
        } else {
            Swal.fire("Error!", "No encontramos lo que buscaba!", "error").then(
                () => {
                    $("#modalAdd").modal("toggle");
                }
            );
        }
        loading.value = false;
    };

    return {
        title_modal,
        method_post,
        form,
        edit,
        submitForm,
        v$,
        loading,
    };
}
