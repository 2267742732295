<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="m-0">Editar perfil</h4>
        </div>
        <div class="card-body">
          <form
            method="POST"
            enctype="multipart/form-data"
            @submit.prevent="submitForm"
            novalidate
          >
            <div class="row">
              <div class="col-md-6">
                <div
                  class="form-group"
                  :class="{ error: v.name.$errors.length }"
                >
                  <label for="name">Nombre *</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    v-model="form.name"
                    class="form-control"
                    placeholder="enforma"
                    required
                  />
                  <error-msg :errors="v.name.$errors"></error-msg>
                </div>
              </div>

              <div class="col-md-6">
                <div
                  class="form-group"
                  :class="{ error: v.email.$errors.length }"
                >
                  <label for="email">Correo electrónico *</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    v-model="form.email"
                    class="form-control"
                    placeholder="enforma@gmail.com"
                    required
                  />
                  <error-msg :errors="v.email.$errors"></error-msg>
                </div>
              </div>

              <div class="col-md-6" v-if="method_post">
                <div
                  class="form-group"
                  :class="{ error: v.password.$errors.length }"
                >
                  <label for="password">Contraseña *</label>
                  <div class="input-group">
                    <input
                      type="password"
                      name="password"
                      ref="password_1"
                      id="password"
                      v-model="form.password"
                      class="form-control"
                      placeholder="* * * * *"
                      minlength="8"
                      maxlength="16"
                      required
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        @click.prevent="showPassword(1)"
                        role="button"
                      >
                        <i class="fa fa-eye"></i>
                      </span>
                    </div>
                  </div>
                  <error-msg :errors="v.password.$errors"></error-msg>
                </div>
              </div>

              <div class="col-md-6" v-if="method_post">
                <div
                  class="form-group"
                  :class="{ error: v.confirm_password.$errors.length }"
                >
                  <label for="password_confirmation"
                    >Confirma contraseña *</label
                  >
                  <div class="input-group">
                    <input
                      type="password"
                      name="password_confirmation"
                      id="password_confirmation"
                      v-model="form.confirm_password"
                      ref="password_2"
                      class="form-control"
                      placeholder="* * * * *"
                      minlength="8"
                      maxlength="16"
                      required
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        @click.prevent="showPassword(2)"
                        role="button"
                      >
                        <i class="fa fa-eye"></i>
                      </span>
                    </div>
                  </div>
                  <error-msg :errors="v.confirm_password.$errors"></error-msg>
                </div>
              </div>

              <div class="col-md-4">
                <div
                  class="form-group"
                  :class="{ error: v.client.birthdate.$errors.length }"
                >
                  <label for="birthdate">Fecha de nacimiento *</label>
                  <input
                    type="date"
                    name="birthdate"
                    v-model="form.client.birthdate"
                    class="form-control"
                    placeholder
                  />
                  <error-msg :errors="v.client.birthdate.$errors"></error-msg>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="birthplace">Lugar de nacimiento</label>
                  <input
                    type="text"
                    name="birthplace"
                    v-model="form.client.birthplace"
                    class="form-control"
                    placeholder
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="civil_status">Estado civil</label>
                  <select
                    class="form-control"
                    name="civil_status"
                    v-model="form.client.civil_status"
                  >
                    <option value disabled>Selecciona una opción</option>
                    <option
                      :value="civil_status.name"
                      v-for="civil_status in civil_statuses"
                      :key="civil_status.id"
                    >
                      {{ civil_status.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="occupation">Ocupación</label>
                  <input
                    type="text"
                    name="occupation"
                    v-model="form.client.occupation"
                    class="form-control"
                    placeholder
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="height">Altura (cm)</label>
                  <input
                    type="number"
                    name="height"
                    v-model="form.client.height"
                    class="form-control"
                    placeholder
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="body_weight">Peso corporal (kg)</label>
                  <input
                    type="number"
                    name="body_weight"
                    v-model="form.client.body_weight"
                    class="form-control"
                    placeholder
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="blood_type">Tipo de sangre</label>
                  <select
                    class="form-control"
                    name="blood_type"
                    v-model="form.client.blood_type"
                  >
                    <option value disabled>Selecciona una opción</option>
                    <option
                      :value="blood_type"
                      v-for="(blood_type, i) in blood_types"
                      :key="i"
                    >
                      {{ blood_type }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- <div class="col-md-4">
                                <div class="form-group">
                                    <label for="activation_type">Tipo de activación</label>
                                    <input
                                        type="text"
                                        name="activation_type"
                                        v-model="form.client.activation_type"
                                        class="form-control"
                                        placeholder
                                    />
                                </div>
                            </div> -->

              <!-- <div class="col-md-6">
                                <div class="row">
                                    <template v-for="(padecimiento, index) in form.client.padecimientos" :key="index">
                                        <div class="col-md-10">
                                            <div class="form-group">
                                                <label for="padecimiento">Padecimiento {{ index + 1 }}</label>
                                                <input
                                                    type="text"
                                                    name="sufferings[]"
                                                    v-model="padecimiento.name"
                                                    class="form-control"
                                                    placeholder
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <template v-if="index > 0">
                                                    <button
                                                        class="btn btn-danger mt-c"
                                                        @click.prevent="destroySuffering(index)"
                                                    >
                                                        <i class="fa fa-times"></i>
                                                    </button>
                                                </template>
                                                <template v-else>
                                                    <button
                                                        class="btn btn-primary mt-c"
                                                        @click.prevent="addSuffering()"
                                                    >
                                                        <i class="fa fa-plus"></i>
                                                    </button>
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>-->

              <div class="col-md-8">
                <div class="form-group">
                  <label for="profile_photo_file">Foto de perfil</label>
                  <input
                    type="file"
                    class="form-control"
                    name="profile_photo_file"
                    accept="image/*"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="invoice">¿Requiere facturación?</label>
                  <div class="form-group mb-0">
                    <label class="radio-inline mr-3">
                      <input
                        type="radio"
                        v-model="form.client.invoice"
                        :value="1"
                        name="invoice"
                      />
                      Si
                    </label>
                    <label class="radio-inline mr-3">
                      <input
                        type="radio"
                        v-model="form.client.invoice"
                        :value="0"
                        name="invoice"
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>

              <!-- contacto -->
              <div class="col-md-12">
                <hr />
                <div class="form-group">
                  <label class="text-primary">Datos de contacto</label>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="phone_number">Número telefónico</label>
                  <input
                    type="number"
                    name="phone_number"
                    v-model="form.client.phone_number"
                    class="form-control"
                    placeholder="000-000-0000"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="cell_phone_number">Número de celular</label>
                  <input
                    type="number"
                    name="cell_phone_number"
                    v-model="form.client.cell_phone_number"
                    class="form-control"
                    placeholder="000-000-0000"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="extra_phone_number"
                    >Número telefónico extra</label
                  >
                  <input
                    type="number"
                    name="extra_phone_number"
                    v-model="form.client.extra_phone_number"
                    class="form-control"
                    placeholder="000-000-0000"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="facebook">Facebook</label>
                  <input
                    type="number"
                    name="facebook"
                    v-model="form.client.facebook"
                    class="form-control"
                    placeholder
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="instagram">Instagram</label>
                  <input
                    type="text"
                    name="instagram"
                    v-model="form.client.instagram"
                    class="form-control"
                    placeholder
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="find_out_gym"
                    >Donde encontrar fuera del gimnasio</label
                  >
                  <input
                    type="text"
                    name="find_out_gym"
                    v-model="form.client.find_out_gym"
                    class="form-control"
                    placeholder
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="communicate_with">Comunicarse con</label>
                  <input
                    type="text"
                    name="communicate_with"
                    v-model="form.client.communicate_with"
                    class="form-control"
                    placeholder
                  />
                </div>
              </div>

              <!-- domicilio -->

              <div class="col-md-12">
                <hr />
                <div class="form-group">
                  <label class="text-primary">Datos de domicilio</label>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="form-group"
                  :class="{ error: v.client.pais.$errors.length }"
                >
                  <label for="pais">País *</label>
                  <input
                    type="text"
                    name="pais"
                    v-model="form.client.pais"
                    class="form-control"
                    placeholder
                  />
                  <error-msg :errors="v.client.pais.$errors"></error-msg>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="form-group"
                  :class="{ error: v.client.ciudad.$errors.length }"
                >
                  <label for="ciudad">Ciudad *</label>
                  <input
                    type="text"
                    name="ciudad"
                    v-model="form.client.ciudad"
                    class="form-control"
                    placeholder
                  />
                  <error-msg :errors="v.client.ciudad.$errors"></error-msg>
                </div>
              </div>

              <div class="col-md-12">
                <div
                  class="form-group"
                  :class="{ error: v.client.calle.$errors.length }"
                >
                  <label for="calle">Calle *</label>
                  <input
                    type="text"
                    name="calle"
                    v-model="form.client.calle"
                    class="form-control"
                    placeholder
                  />
                  <error-msg :errors="v.client.calle.$errors"></error-msg>
                </div>
              </div>

              <div class="col-md-4">
                <div
                  class="form-group"
                  :class="{ error: v.client.codigo_postal.$errors.length }"
                >
                  <label for="codigo_postal">Código Postal *</label>
                  <input
                    type="text"
                    name="codigo_postal"
                    v-model="form.client.codigo_postal"
                    class="form-control"
                    placeholder
                  />
                  <error-msg
                    :errors="v.client.codigo_postal.$errors"
                  ></error-msg>
                </div>
              </div>

              <div class="col-md-4">
                <div
                  class="form-group"
                  :class="{ error: v.client.colonia.$errors.length }"
                >
                  <label for="colonia">colonia *</label>
                  <input
                    type="text"
                    name="colonia"
                    v-model="form.client.colonia"
                    class="form-control"
                    placeholder
                  />
                  <error-msg :errors="v.client.colonia.$errors"></error-msg>
                </div>
              </div>

              <div class="col-md-4">
                <div
                  class="form-group"
                  :class="{ error: v.client.numero.$errors.length }"
                >
                  <label for="numero">Número *</label>
                  <input
                    type="number"
                    name="numero"
                    v-model="form.client.numero"
                    class="form-control"
                    placeholder
                  />
                  <error-msg :errors="v.client.numero.$errors"></error-msg>
                </div>
              </div>

              <div class="col-md-12">
                <hr />
              </div>

              <div class="col-md-12">
                <div class="d-flex justify-content-end align-items-center">
                  <router-link to="/perfil" class="btn btn-danger light"
                    >Cancelar</router-link
                  >
                  <button
                    :class="[
                      'btn btn-primary ml-2 d-flex justify-content-lg-around align-items-center',
                      { 'not-allowed': loading },
                    ]"
                    :disabled="loading"
                  >
                    <c-spinner
                      :loading="loading"
                      :width="16"
                      :height="16"
                    ></c-spinner>
                    <span class="ml-1">Guardar</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import ErrorMsg from "@/components/modals/ErrorMsg.vue";
import useProfileForm from "@/composables/profile/useProfileForm.js";
import CSpinner from "../../components/spinners/CSpinner.vue";
export default {
  components: {
    ErrorMsg,
    CSpinner,
  },
  setup() {
    const civil_statuses = [
      { id: 1, value: "soltero(a)", name: "Soltero(a)" },
      { id: 2, value: "casado(a)", name: "Casado(a)" },
      { id: 3, value: "divorciado(a)", name: "Divorciado(a)" },
      { id: 4, value: "viudo(a)", name: "Viudo(a)" },
      { id: 5, value: "union_libre", name: "Union libre" },
    ];

    const blood_types = ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"];

    const {
      title_modal,
      method_post,
      form,
      edit,
      submitForm,
      v$: v,
      loading,
    } = useProfileForm();

    edit();

    const destroySuffering = (index) => {
      form.client.padecimientos.splice(index, 1);
    };

    const addSuffering = () => {
      form.client.padecimientos.push({
        name: "",
      });
    };

    const formHasErrors = computed(() => {
      return v.$silentErrors;
    });

    return {
      destroySuffering,
      addSuffering,
      civil_statuses,
      blood_types,
      formHasErrors,

      title_modal,
      method_post,
      form,
      edit,
      submitForm,
      v,
      loading,
    };
  },
  methods: {
    showPassword(numPass) {
      if (this.$refs[`password_${numPass}`].type == "password") {
        this.$refs[`password_${numPass}`].type = "text";
        this[`password_${numPass}`] = true;
      } else {
        this.$refs[`password_${numPass}`].type = "password";
        this[`password_${numPass}`] = false;
      }
    },
  },
};
</script>

<style scoped>
.error input {
  border-color: #f79483;
}
.mt-c {
  margin-top: 1.8rem;
}
</style>
