import { required, requiredIf, email, helpers, sameAs } from '@vuelidate/validators'
import { computed } from 'vue-demi';
/**
 *  Messages
 *  Este campo solo permite caracteres Númericos.
 *  Este campo es obligatorio.
 */

export default function ClientsAddRules(method_post, form){
    const getPassword = computed(() => form.password)
    const rules = {
        name: {
            required: helpers.withMessage('Este campo es obligatorio.', required),
        },
        email: {
            required: helpers.withMessage('Este campo es obligatorio.', required),
            email: helpers.withMessage('Inserte un email valido.', email)
        },
        password: {
            requiredIfMethodPost: helpers.withMessage('Este campo es obligatorio.', requiredIf(method_post)),
        },
        confirm_password: {
            requiredIfMethodPost: helpers.withMessage('Este campo es obligatorio.', requiredIf(method_post)),
            sameAsPassword: helpers.withMessage('Las contraseñas no coinciden.', sameAs(getPassword))
        },
        client: {
            birthdate: {
                required: helpers.withMessage('Este campo es obligatorio.', required),
            },
            calle: {
                required: helpers.withMessage('Este campo es obligatorio.', required),
            },
            numero: {
                required: helpers.withMessage('Este campo es obligatorio.', required),
            },
            colonia: {
                required: helpers.withMessage('Este campo es obligatorio.', required),
            },
            pais: {
                required: helpers.withMessage('Este campo es obligatorio.', required),
            },
            ciudad: {
                required: helpers.withMessage('Este campo es obligatorio.', required),
            },
            codigo_postal: {
                required: helpers.withMessage('Este campo es obligatorio.', required),
            },
        }
    };


    return {
        rules
    }
}
